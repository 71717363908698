import React, { Component } from "react";

export default function FeaturesOne(props) {
  return (
    <section
      className="features-wrapper section-padding"
      style={{ background: "#004caa" }}
    >
      <div className="container pt-6 pt-md-0">
        <div className="row">
          {props.items.map((item) => {
            return (
              <div className="col-md-6 col-12 col-lg-6 col-xl-4" key={item.id}>
                <div className="single-feature-box">
                  <div className="icon-box">
                    <img
                      src={require("../../assets/img/icons/" + item.imgUrl)}
                      alt={item.title}
                    />
                  </div>
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                  <a href={item.link}>{item.linkText}</a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
