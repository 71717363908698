import React, { Component } from "react";
import SectionTitleOne from "../SectionTitleOne";
import { Link } from "react-router-dom";
import Dentist from "./Dentist";

const displayDentists = (TeamData, language) => {
  return TeamData.map(member => {
    return member.isDentist ? (
      <Dentist key={member.id} member={member} language={language} />
    ) : (
      null
    );
  });
};

const TeamOne = (props) => {
  const { TeamData, language } = props;

  return (
    <section className="doctors-wrapper section-bg section-padding">
      <div className="container">
        <div className="text-white">
          <SectionTitleOne
            BigTitle={
              props.language === "esp" ? "Nuestros Dentistas" : "Our Dentists"
            }
          />
        </div>

        <div className="row text-center justify-content-center">
          {displayDentists(TeamData, language)}
        </div>
        <div
          className="row d-flex justify-content-center"
          style={{
            marginBottom: "-5rem",
            marginTop: "3rem",
            fontSize: "20px",
          }}
        >
          <Link style={{ color: "white" }} to="/Team">
            <button className="submit-btn">
              {props.language === "esp"
                ? "¡Conozca a nuestro equipo!"
                : "Meet our team!"}
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
