import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { withCookies, useCookies } from 'react-cookie';
import HomeOne from './Components/HomeOne';
import Header from './Components/Header/Header';
import FooterOne from './Components/Footer/FooterOne';
import About from './Components/Page/About';
import Error404 from './Components/Page/404';
import ServiceList from './Components/Page/ServiceList';
import ServiceDetails from './Components/Page/ServiceDetails';
import DentistDetails from './Components/Page/DentistDetails';
import PhotoGallery from './Components/Page/PhotoGallery';
import Contact from './Components/Page/Contact';
import Team from './Components/Page/Team';
import FooterData from './Components/Data/FooterData';
import ScrollToTop from './Components/Helpers/ScrollToTop';
import ScrollButton from './Components/Helpers/ScrollToTopBtn';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { Reducer } from './redux/reducer';
import * as Sentry from '@sentry/react';

import './App.css';

function FallbackComponent() {
  return <Redirect to='/Page/404' />;
}
const appFallback = <FallbackComponent />;

function App() {
  const [cookies, setCookie] = useCookies(['language']);
  const initialState = {
    language: cookies?.language ?? 'eng',
  };
  const store = createStore(Reducer, initialState);

  return (
    <Provider store={store}>
      <Router>
        <Sentry.ErrorBoundary fallback={appFallback}>
          <ScrollToTop />
          <Header />
          <ScrollButton />
          <Route exact path='/' component={HomeOne} />
          <Route path='/About' component={About} />
          <Route path='/404' component={Error404} />
          <Route path='/Services' component={ServiceList} />
          <Route path='/ServiceDetails/:slug' component={ServiceDetails} />
          <Route path='/DentistDetails/:dentistId' component={DentistDetails} />
          <Route path='/PhotoGallery' component={PhotoGallery} />
          <Route path='/Team' component={Team} />
          <Route path='/Contact' component={Contact} />

          <FooterOne FooterData={FooterData} />
        </Sentry.ErrorBoundary>
      </Router>
    </Provider>
  );
}

export default withCookies(App);
