import React from "react";
import Appointment from "../Appointment";

const Error404 = () => {
  return (
    <>
      <div className="container page-banner">
        <h1>404 Error</h1>
        <p style={{ fontWeight: 400 }}>
          The page you are looking for could not be found. Please try again or
          contact the clinic.
        </p>
      </div>
      <Appointment title={"Contact Us"} />
    </>
  );
};

export default Error404;
