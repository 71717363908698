import React from "react";

export default function Location(props) {
  return (
    <div
      id="location-map"
      className="row align-items-center justify-content-center"
    >
      <iframe
        title="Dental Access Texas"
        width={props.width}
        height={props.height}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        id="gmap_canvas"
        src="https://maps.google.com/maps?width=800&amp;height=500&amp;hl=en&amp;q=1119%20W%20Randol%20Mill%20Rd%20Suite%20101%20Arlington+(Dental%20Access%20Texas)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
      <script
        type="text/javascript"
        src="https://embedmaps.com/google-maps-authorization/script.js?id=2bbbd10c36677381f0704dc2e9bdaf4779700192"
      ></script>
    </div>
  );
}
