import React from 'react';
import SectionTitleOne from '../SectionTitleOne';
import ServiceItem from './ServiceItem';

const ServiceOne = ({ ServiceData, language }) => {
  let readLabel = language === 'esp' ? 'lee mas' : 'read more';

  return (
    <section className='services-wrapper section-bg section-padding text-white'>
      <div className='container'>
        <SectionTitleOne
          BigTitle={language === 'esp' ? 'Tratamientos' : 'Treatments'}
        />

        <div className='row'>
          {ServiceData.map((item) => {
            return (
              <ServiceItem
                key={item.id}
                slug={item.slug}
                ID={item.id}
                Title={item.title}
                ImageURL={item.imgUrl}
                Text={item.text}
                ReadLabel={readLabel}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ServiceOne;
