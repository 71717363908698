const ServiceData = {
  serviceItems_Eng: [
    {
      id: 1,
      imgUrl: 'services/012-teeth cleaning.svg',
      slug: 'cleaning',
      title: 'Cleaning / Hygiene',
      text: 'Our clinic offers basic cleaning as well as gross scaling and deep scaling for patients who require more. Our highly experienced hygiene team with the general dentist will evaluate, diagnose and treat your oral health care needs.',
    },
    {
      id: 2,
      imgUrl: 'services/043-broken tooth.svg',
      slug: 'emergency-dentistry',
      title: 'Emergency Dentistry',
      text: 'Chipped tooth? Filling came out? Toothache? Swelling? Crown fell off? Dental Access do understand that life happens and we offer same-day dentistry.',
    },
    {
      id: 3,
      imgUrl: 'services/041-tooth extraction.svg',
      slug: 'oral-surgery',
      title: 'Oral Surgery / Extractions',
      text: "Damaged teeth that are unrepairable, infected teeth that can't be healed, teeth that causes overcrowding, and/or wisdom teeth that are prone to infections are recommended to be extracted.",
    },
    {
      id: 4,
      imgUrl: 'services/001-braces.svg',
      slug: 'orthodontics',
      title: 'Orthodontics / Braces / Invisalign®',
      text: "A beautiful smile is not hard to achieve.  We, at Dental Access, can give you the smile you've always wanted.  Our clinics offer traditional braces as well as the clear, non-metal options.",
    },
    {
      id: 5,
      imgUrl: 'services/023-tooth whitening.svg',
      slug: 'whitening',
      title: 'Whitening / Bleaching',
      text: 'Your smile is the first thing you notice when meeting someone.  We can help you acheive that beautiful, whiter and brighter smile with our professional in office or take home whitening.',
    },
    {
      id: 6,
      imgUrl: 'services/044-implant.svg',
      slug: 'implants',
      title: 'Implants / All-On-X',
      text: 'Tired of having an empty space in your mouth or that uncomfortable denture? Tired of wearing a partial or flipper?  We can restore your chewing function and your smile with implants.  Our implant consultation and 3-D x-rays are free.  ',
    },
    {
      id: 7,
      imgUrl: 'services/011-caries.svg',
      slug: 'fillings',
      title: 'Fillings / Bondings',
      text: 'Cavities should be taken care of as soon as possible to avoid more costly dental procedures.',
    },
    {
      id: 8,
      imgUrl: 'services/denture.svg',
      slug: 'dentures',
      title: 'Dentures / Partial Dentures',
      text: 'Dentures, commonly known as false teeth, are fitted to act as a prosthesis for missing teeth. They are often made of acrylic, nylon, or metal.',
    },
    {
      id: 9,
      imgUrl: 'services/dental-crown.svg',
      slug: 'crowns',
      title: 'Crowns / Veneers / Bridges',
      text: 'Dental Access offer same day crowns and bridges as well as traditional two appointments crown and bridges procedures. We also offer veneers and smile make over for the aesthetically conscious patients.',
    },
    {
      id: 10,
      imgUrl: 'services/051-root_canal.svg',
      slug: 'root-canal',
      title: 'Root Canal',
      text: 'When a tooth becomes infected, it is related to the nerves in the root of the tooth.  The infected nerves need to be removed.  If left untreated, an infection can turn into an abscess, which can result in more serious bone loss and pain.',
    },
    {
      id: 11,
      imgUrl: 'services/046-tooth.svg',
      slug: 'night-guards',
      title: 'Night Guards / Sports Guards / TMJ Treatments',
      text: 'Grinding your teeth to a nub? Migraine headaches? Jaw pain?  We offer custom nightguards that will help prevent teeth grinding.',
    },
    {
      id: 12,
      imgUrl: 'services/005-dentist chair.svg',
      slug: 'sleep-apnea',
      title: 'Sleep Apnea',
      text: 'Loud Snoring? Sleeping Disorder? Daytime tiredness?  If left untreated, it can lead to serious health problems.  At Dental Access, we can help you alleviate these symptoms so that you can finally get a good night’s rest.',
    },
  ],
  serviceItems_Esp: [
    {
      id: 1,
      imgUrl: 'services/012-teeth cleaning.svg',
      slug: 'cleaning',
      title: 'Limpieza / Higiene',
      text: 'Nuestra clínica ofrece limpieza básica, así como escalado grueso y profundo para pacientes que requieren más. Nuestro equipo de higiene bien experimentado junto con el dentista general evaluará, diagnosticará, y tratará sus necesidades de la salud bucal.',
    },
    {
      id: 2,
      imgUrl: 'services/043-broken tooth.svg',
      slug: 'emergency-dentistry',
      title: 'Odontología de Emergencia',
      text: '¿Diente astillado? ¿El relleno se salió? ¿Dolor de dientes? ¿Hinchazón? ¿La corona se cayó? Dental Access comprende que la vida pasa y ofrecemos odontología el mismo día.',
    },
    {
      id: 3,
      imgUrl: 'services/041-tooth extraction.svg',
      slug: 'oral-surgery',
      title: 'Cirugía Oral / Extracciones',
      text: 'Se recomienda extraer los dientes dañados que son irreparables, los dientes infectados que no se pueden curar, los dientes que causan hacinamiento o las muelas del juicio que son propensas a infecciones.',
    },
    {
      id: 4,
      imgUrl: 'services/001-braces.svg',
      slug: 'orthodontics',
      title: 'Ortodoncia / Frenos / Invisalign®',
      text: 'Una hermosa sonrisa no es difícil de lograr. Nosotros en Dental Access podemos brindarle la sonrisa que siempre ha deseado. Nuestras clínicas ofrecen aparatos de ortodoncia tradicionales, así como opciones transparentes no metálicas.',
    },
    {
      id: 5,
      imgUrl: 'services/023-tooth whitening.svg',
      slug: 'whitening',
      title: 'Blanqueamiento',
      text: 'Su sonrisa es lo primero que se nota al conocer a alguien. Podemos ayudarlo a lograr esa sonrisa hermosa, más blanca, y brillante con nuestro blanqueamiento profesional en la oficina o para llevar a casa.',
    },
    {
      id: 6,
      imgUrl: 'services/044-implant.svg',
      slug: 'implants',
      title: 'Implantes / All-On-X',
      text: '¿Cansado de tener un espacio vacío en la boca o esa incómoda dentadura postiza? ¿Estás cansado de llevar un parcial o flipper? Podemos restaurar su función masticatoria y sonrisa con implantes. Nuestra consulta de implantes y radiografía tridimensional es gratuita.',
    },
    {
      id: 7,
      imgUrl: 'services/011-caries.svg',
      slug: 'fillings',
      title: 'Empastes / Adhesión',
      text: 'Las caries deben ser tratados lo antes posible para evitar procedimientos dentales más costosos.',
    },
    {
      id: 8,
      imgUrl: 'services/denture.svg',
      slug: 'dentures',
      title: 'Dentaduras Postizas / Dentaduras Parciales',
      text: 'Las dentaduras postizas, conocidas comúnmente como dientes falsos, están equipados para actuar como una prótesis para dientes perdidos. A menudo están hechos de acrílico, nailon o metal.',
    },
    {
      id: 9,
      imgUrl: 'services/dental-crown.svg',
      slug: 'crowns',
      title: 'Coronas / Carillas / Puentes',
      text: 'Dental Access ofrece coronas y puentes el mismo día, así como procedimientos tradicionales de coronas y puentes de dos citas. También ofrecemos carillas y cambio de sonrisa para los pacientes con conciencia estéticamente conscientes.',
    },
    {
      id: 10,
      imgUrl: 'services/051-root_canal.svg',
      slug: 'root-canal',
      title: 'Tratamiento de Conducto',
      text: 'Cuando un diente se infecta, está relacionado con los nervios en la raíz del diente. Es necesario extirpar los nervios infectados. Si no se trata, una infección puede convertirse en un absceso, lo que puede provocar una pérdida ósea más grave y dolor.',
    },
    {
      id: 11,
      imgUrl: 'services/046-tooth.svg',
      slug: 'night-guards',
      title: 'Guardia Nocturna  / Guardia de Deporte / Tratamientos de TMJ',
      text: '¿Rechina los dientes hasta convertirse en una protuberancia? ¿Migrañas? ¿Dolor de mandibula? Ofrecemos protectores nocturnos personalizados que ayudarán a prevenir el rechinar de dientes.',
    },
    {
      id: 12,
      imgUrl: 'services/005-dentist chair.svg',
      slug: 'sleep-apnea',
      title: 'Apnea del Sueño',
      text: '¿Ronquidos fuertes? ¿Trastorno del sueño? ¿Cansancio por el día? Si no se trata, puede provocar problemas de salud graves. En Dental Access, podemos ayudarlo a aliviar estos síntomas para que finalmente pueda descansar bien por la noche.',
    },
  ],
};

export default ServiceData;
