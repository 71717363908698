import React from "react";
import { Link } from "react-router-dom";

export default function Banner(props) {
  let { pageTitle } = props;
  return (
    <section className="breadcrumb-wrap page-banner text-white section-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="page-banner-breadcrumb">
              <h1>{pageTitle}</h1>
            </div>
            <div className="breadcrumb-nav">
              <Link to="/">
                {" "}
                {props.language === "esp" ? "Pagina de inicio" : "Home Page"}
              </Link>
              {props.BreadCrumb ? (
                <Link to={`/${props.BreadCrumbEng}`}>{props.BreadCrumb}</Link>
              ) : null}
              <p style={{ display: "inline" }}> {pageTitle} </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="page-banner-img">
              <div className="banner-animate">
                <img
                  src={require("../assets/img/page-banner/1.png")}
                  alt=""
                  className="a1"
                />
                <img
                  src={require("../assets/img/page-banner/2.png")}
                  className="bannerimg a2"
                  alt=""
                />
                <img
                  src={require("../assets/img/page-banner/3.png")}
                  className="bannerimg a3"
                  alt=""
                />
                <img
                  src={require("../assets/img/page-banner/4.png")}
                  className="bannerimg a4"
                  alt=""
                />
                <img
                  src={require("../assets/img/page-banner/5.png")}
                  className="bannerimg a5"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
