import React from "react";

export default function Fillings(props) {
  if (props.language === "esp") {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h3>Empastes con Color de Diente </h3>
                  <p>
                    Cuando los dientes tienen caries y necesitan empastes,
                    nuestro dentista puede eliminar las caries y reemplazarlas
                    con un empaste con color de diente. Sus dientes se verán
                    como si nunca hubiera tenido caries.
                  </p>
                  <br />
                  <h3>Empastes Seguros de Metal con Amalgama </h3>
                  <p>
                    Nuestra clínica también ofrece empastes seguros de metal sin
                    amalgama para aquellos que no prefieren empastes con color
                    de diente.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentalcavities.png")}
                  alt="Cavities"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h3>Tooth-Colored Fillings</h3>
                  <p>
                    When a tooth has cavities and need fillings, our dentist can
                    remove the cavities and replace it with tooth-colored
                    fillings. Your tooth will look like the cavities were never
                    there.
                  </p>
                  <br />
                  <h3>Amalgam Safe Metal Fillings </h3>
                  <p>
                    Our clinic also offers amalgam-free, safe metal fillings for
                    those who does not prefer tooth-colored fillings.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentalcavities.png")}
                  alt="Cavities"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
