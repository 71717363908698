import React, { Component } from "react";
import { Link } from "react-router-dom";

export default function Dentist(props) {
  let member = props.member;
  return (
    <div className="col-md-6 col-lg-4 col-12" key={member.id}>
      <div className="single-doctor">
        <div className="doctor-profile">
          <img src={require("../../assets/img/team/" + member.imgUrl)} alt="" />
        </div>
        <div className="doctor-info">
          <h3>{member.name}</h3>
          <h4>
            {props.language === "esp" && member.position_esp
              ? member.position_esp
              : member.position}
          </h4>
          {member.link ? (
            <p>
              <b>
                <Link to={member.link}>
                  {props.language === "esp"
                    ? "¡Más Información!"
                    : "More info!"}
                </Link>
              </b>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}
