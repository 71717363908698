import { BUSINESS_DATA_01 } from "./BusinessData";
const FeaturesData = {
  items_eng: [
    {
      id: 1,
      imgUrl: "services/027-dentist.svg",
      title: "Easy Appointment",
      text: "Scheduling with us is simple.  Call us or book an appointment.  We do same-day appointments!",
      linkText: "Schedule now!",
      link: "#appointment",
    },
    {
      id: 2,
      imgUrl: "services/018-pharmacy.svg",
      title: "Emergency Service",
      text: "Need immediate dental service?  Call us so that we can help you right away!",
      linkText: "Call now!",
      link: `tel:+1${BUSINESS_DATA_01.phone}`,
    },
    {
      id: 3,
      imgUrl: "services/031-dental insurance.svg",
      title: "Insurance or No Insurance",
      text: "Whether you have insurance or not, we have options for you!",
      linkText: "Read more!",
      link: "#insuranceSection",
    },
  ],
  items_esp: [
    {
      id: 1,
      imgUrl: "services/027-dentist.svg",
      title: "Cita Fácil",
      text: "Programar con nosotros es simple; llámenos o reserva una cita. ¡Hacemos citas el mismo día!",
      linkText: "¡Programar ahora!",
      link: "#appointment",
    },
    {
      id: 2,
      imgUrl: "services/018-pharmacy.svg",
      title: "Servicio de Emergencia",
      text: "¿Necesita un servicio dental inmediato? ¡Llámenos para que podamos ayudarle de inmediato!",
      linkText: "¡Llama ahora!",
      link: `tel:+1${BUSINESS_DATA_01.phone}`,
    },
    {
      id: 3,
      imgUrl: "services/031-dental insurance.svg",
      title: "Asegurado o No Asegurado",
      text: "Ya sea que tenga seguro o no, ¡tenemos opciones para usted!",
      linkText: "¡Leer mas!",
      link: "#insuranceSection",
    },
  ],
};

export default FeaturesData;
