import React from 'react';
import Navbar from "./Navbar";
import { changeLanguage } from "../../redux/ActionCreators";
import { connect, dispatch } from "react-redux";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => dispatch(changeLanguage(language)),
  };
};


const Header = ({changeLanguage}) => {
   return (
    <Navbar changeLanguage={changeLanguage} />
   );
};



export default connect(mapStateToProps, mapDispatchToProps)(Header);
