import React from "react";
import { connect } from "react-redux";
import Banner from "../Banner";
import Dentist from "../Team/Dentist";
import TeamData from "../Data/TeamData";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const Team = ({ language }) => {
  return (
    <React.Fragment>
      <Banner
        pageTitle={
          language === "esp" ? "Nuestro Equipo Dental" : "Our Dental Team"
        }
        language={language}
      />

      <section className="doctors-wrapper section-padding">
        <div className="container">
          <div className="row text-center">
            {TeamData.map((member) => {
              return (
                <Dentist key={member.id} member={member} language={language} />
              );
            })}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(Team);
