const BUSINESS_DATA_01 = {
  name: "Dental Access",
  addressStreet: "1119 W Randol Mill Rd #101",
  addressCity: "Arlington",
  addressState: "TX",
  addressZipCode: "76012",
  phone: "(817) 422-9381",
  email: "info@dentalaccesstexas.com",
  facebook: "https://www.facebook.com/dentalaccesstexas/",
  instagram: "https://www.instagram.com/dentalaccesstexas/",
  google: "https://g.page/r/CRTfZqrAk3_6EAE",
};

const BUSINESS_DATA_02 = {
  name: "Dental Access",
  addressStreet: "4320 S. State Highway 360 #300",
  addressCity: "Grand Prairie",
  addressState: "TX",
  addressZipCode: "75052",
  phone: "(817) 422-9381",
  email: "info@dentalaccessgp.com",
  facebook: "https://www.facebook.com/dentalaccesstexas/",
  instagram: "https://www.instagram.com/dentalaccesstexas/",
};

export { BUSINESS_DATA_01, BUSINESS_DATA_02 };
