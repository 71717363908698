import React from "react";
import Banner from "../Banner";
import SectionTitleOne from "../SectionTitleOne";
import Appointment from "../Appointment";
import { AboutPage } from "../Data/PageData";
import { connect } from "react-redux";
import getLanguageData from "../Helpers/getLanguageData";
import TeamOne from "../Team/TeamOne";
import TeamData from "../Data/TeamData";
import Location from "../location";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const About = ({ language }) => {
  const pageData = getLanguageData(language, AboutPage.eng, AboutPage.esp);

  return (
    <>
      <Banner
        pageTitle={language === "esp" ? "Sobre Nosotros" : "About Us"}
        language={language}
      />

      <section className="about-page-top-content-wrap section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
              <div className="section-text-wrap">
                <h1>{pageData.mission.header}</h1>
                <p style={{ fontSize: "20px" }}>{pageData.mission.text}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="patient-focused-wrap section-padding text-white section-bg text-center">
        <div className="container">
          <SectionTitleOne
            BigTitle={
              language === "esp"
                ? "Enfoque Centrado en el Paciente"
                : "Patient Focused Approach"
            }
          />

          <div className="row">
            <div className="col-md-6 col-lg-4 col-12">
              <div className="best-service-item-box">
                <div className="service-box-icon">
                  <img
                    src={require("../../assets/img/icons/love.png")}
                    alt="heart"
                  />
                </div>
                <h3>{language === "esp" ? "Transparencia" : "Transparency"}</h3>
                <p>
                  {language === "esp"
                    ? "Nos aseguramos de que todo está claro y transparente para usted"
                    : "We ensure that everything is clear and transparent for you"}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-12">
              <div className="best-service-item-box">
                <div className="service-box-icon">
                  <img
                    src={require("../../assets/img/icons/like.png")}
                    alt="heart text"
                  />
                </div>
                <h3>{language === "esp" ? "Familiaridad" : "Familiarity"}</h3>
                <p>
                  {language === "esp"
                    ? "Nos aseguramos de que se sienta cómodo y confiado con nosotros"
                    : "We ensure you are comfortable and confident with us"}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-12">
              <div className="best-service-item-box">
                <div className="service-box-icon">
                  <img
                    src={require("../../assets/img/icons/respect.png")}
                    alt="handshake"
                  />
                </div>
                <h3>{language === "esp" ? "Resultado" : "Outcome"}</h3>
                <p>
                  {language === "esp"
                    ? "Nos aseguramos de que salgas con la mayor sonrisa"
                    : "We ensure you walk out with the biggest smile"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="promo-content-section promo-bg section-padding">
        <div className="container">
          <div className="row align-items-center justify-content-center mb-5">
            <h1 className="text-center">
              {language === "esp" ? "Nuestra Ubicación" : "Our Location"}
            </h1>
          </div>
          <Location width="800" height="500" />
        </div>
      </section>

      <TeamOne TeamData={TeamData} language={language} />
      <Appointment
        title={language === "esp" ? "Solicitar Cita" : "Request Appointment"}
        language={language}
      />
    </>
  );
};

export default connect(mapStateToProps)(About);
