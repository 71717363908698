import React from "react";

export default function NightGuards(props) {
  if (props.language === "esp") {
    return (
      <section className="promo-content-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-6 col-12">
              <div className="promo-text">
                <h3>Guardia nocturna </h3>
                <p>
                  Muchas personas no saben que rechinan los dientes mientras
                  duermen. Si no se controla, puede provocar daños en los
                  dientes, falta de sueño, migrañas, dolor de mandíbula y otros
                  problemas. En Dental Access, nuestros médicos lo ayudan a
                  remediar los dolores de mandíbula y evitar que sus dientes se
                  desgasten. Nuestros protectores nocturnos son duraderos y
                  están hechos a medida para adaptarse perfectamente a sus
                  dientes.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
              <img
                className="rounded"
                src={require("../../../assets/img/nightguard.jpg")}
                alt="Cavities"
              />
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="promo-content-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-6 col-12">
              <div className="promo-text">
                <h3>Night Guards</h3>
                <p>
                  Many people are unaware they grind their teeth during their
                  sleep. If left unmanaged, it can lead to tooth damage, poor
                  sleep, migraines, jaw pain, and other problems. At Dental
                  Access, our doctors help you remedy the jaw pains and stop
                  your teeth from wearing down. Our nightguards are durable and
                  custom made to fit your teeth perfectly.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
              <img
                className="rounded"
                src={require("../../../assets/img/nightguard.jpg")}
                alt="Cavities"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
