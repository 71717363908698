import React from "react";

export default function Orthodontics(props) {
  if (props.language === "esp") {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <p>
                    Los aparatos ortopédicos y alineadores son las soluciones
                    perfectas para enderezar los dientes. Dental Access también
                    ofrece un sistema de frenos rápido para que tenga menos
                    visitas al dentista. No se detenga debido a dientes torcidos
                    o apiñados. Para todos los que puedan relacionarse con los
                    siguientes síntomas y consecuencias; podemos ayudar a
                    aliviar los problemas y brindarle una hermosa sonrisa
                    saludable:
                  </p>
                  <ol>
                    <li>
                      <b>Maloclusión</b> - Desalineación de los dientes
                      superiores e inferiores donde se encuentran demasiado
                      hacia adelante
                    </li>
                    <li>
                      <b>Mordida Cruzada y Mordida Abierta</b> - Desalineación
                      de los arcos dentales hacia afuera o hacia adentro
                    </li>
                    <li>
                      <b>Línea Media mal Colocada</b> - desalineación de los
                      dientes frontales superiores medios con los dientes
                      frontales inferiores centrales
                    </li>
                    <li>
                      <b>Espaciado y Apiñamiento:</b> - la cresta dental es
                      demasiado espaciosa o está demasiado congestionada para
                      acomodar todos los demás dientes
                    </li>
                  </ol>
                  <p>
                    Las afecciones dentales como estas no son solo una
                    preocupación estética. Las posiciones inadecuadas de los
                    dientes dificultan mucho la limpieza; y provocará caries,
                    dolor de mandíbula, dolor de cabeza, y problemas digestivos.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentalbraces.png")}
                  alt="braces"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <p>
                    Braces and aligners are the perfect solutions to straighten
                    teeth. Dental Access also offer fast braces system so you
                    will have fewer visits to the dentist. Don\'t be held back
                    because of crooked or crowed teeth. For all that can relate
                    to the following symptoms and consequences, we can help
                    alleviate the issues and give you a beautiful healthy smile:
                  </p>
                  <ol>
                    <li>
                      <b>Overbite and Underbite</b> - Misalignment of upper and
                      lower teeth where either lie far too forward
                    </li>
                    <li>
                      <b>Cross Bite and Open Bite</b> - Misalignment of dental
                      arches outwardly or inwardly
                    </li>
                    <li>
                      <b>Misplaced Midline</b> - Misalignment of middle upper
                      front teeth with center lower front teeth
                    </li>
                    <li>
                      <b>Spacing and Crowding</b> - The dental ridge is too
                      roomy or is too congested to accommodate all of the other
                      teeth
                    </li>
                  </ol>
                  <p>
                    Dental conditions like these are not just an aesthetic
                    concern. The improper positions of teeth make it very
                    difficult to clean; thus will lead to tooth decay, jaw pain,
                    headache, and digestive issues.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded"
                  src={require("../../../assets/img/dentalbraces.png")}
                  alt="braces"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
