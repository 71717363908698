import React from "react";
import Appointment from "../Appointment";
import Banner from "../Banner";
import { Redirect, useParams } from "react-router-dom";
import { connect } from "react-redux";
import TeamData from "../Data/TeamData";
import getLanguageData from "../Helpers/getLanguageData";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const DentistDetails = ({ language }) => {
  const { dentistId } = useParams();
  const id = Number(dentistId);
  let inList = false;
  if (dentistId) {
    TeamData.forEach((item) => {
      if (item.id === id) {
        inList = true;
      }
    });
  }

  if (!id || !inList) {
    return <Redirect to="/404" />;
  }

  const teamMember = TeamData.filter((member) => member.id === id)[0];

  const description = getLanguageData(
    language,
    teamMember.description_eng,
    teamMember.description_esp
  );
  return (
    <>
      <Banner
        pageTitle={`Dr. ${teamMember.name}`}
        BreadCrumb={language === "esp" ? "Equipo" : "Team"}
        BreadCrumbEng="Team"
        language={language}
      />

      <section className="dentist-details-wrap section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-8 col-12">
              <div className="single-dentist-details">
                <h2>
                  {`Dr. ${teamMember.name}`}{" "}
                  <span>{`(${teamMember.position})`}</span>
                </h2>
                {description.map((description, index) => {
                  return <p key={index}>{description}</p>;
                })}
              </div>
            </div>
            <div className="col-md-5 col-lg-4 col-12 order-first order-md-last">
              <div className="dentist-profile-details">
                <div className="doctor-profile">
                  <img
                    src={require("../../assets/img/team/" + teamMember.imgUrl)}
                    alt=""
                  />
                </div>
                <p>
                  {language === "esp" ? "Nombre" : "Name:"}{" "}
                  <strong>{`Dr. ${teamMember.name}`}</strong>
                </p>
                <p>
                  <strong>{teamMember.position}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Appointment
        title={language === "esp" ? "Solicitar Cita" : "Request Appointment"}
        language={language}
      />
    </>
  );
};

export default connect(mapStateToProps)(DentistDetails);
