import React from "react";

export default function RootCanal(props) {
  if (props.language === "esp") {
    return (
      <React.Fragment>
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>¿Qué es un Tratamiento de Conducto?</h2>
                  <p>
                    Un tratamiento dental que implica la extracción de la pulpa
                    del diente. La pulpa está formada por nervios, tejido
                    conectivo y vasos sanguíneos que ayudan a que el diente
                    crezca. Se necesita cuando la pulpa se lesiona o se inflama
                    o infecta. Quitar la pulpa lesionada o infectada es la mejor
                    manera de preservar la estructura del diente.
                  </p>
                  <br /> <h4>Causas comunes de daño a la pulpa:</h4>
                  <ul>
                    <li>Deterioro profundo debido a una carie no tratada</li>
                    <li>
                      Múltiples procedimientos dentales en el mismo diente
                    </li>
                    <li>Una astilla o grieta en el diente</li>
                    <li> Una lesión en el diente</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <img
                  className="rounded mt-5"
                  src={require("../../../assets/img/dentalrootcanal01.png")}
                  alt="Root Canal"
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded mt-5"
                  src={require("../../../assets/img/InspectingTooth.png")}
                  alt="Inspecting Tooth"
                />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text"></div>
                <div className="my-5">
                  <h2>Riesgos de un Tratamiento de Conducto</h2>
                  <p>
                    El tratamiento se realiza en un esfuerzo por salvar su
                    diente. A veces, sin embargo, el daño es demasiado profundo
                    o el esmalte es demasiado frágil para soportar el
                    procedimiento. Estos factores pueden provocar la pérdida del
                    diente.
                    <br /> Otro riesgo es desarrollar un absceso en la raíz del
                    diente si los antibióticos no son efectivos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        '
        <section className="promo-content-section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text">
                  <h2>What is a Root Canal?</h2>
                  <p>
                    A root canal is a dental procedure involving the removal of
                    the pulp of the tooth. The pulp is made up of nerves,
                    connective tissue, and blood vessels that help the tooth
                    grow. A root canal is needed when the pulp is injured,
                    becomes inflamed, or infected. Removing injured or infected
                    pulp is the best way to preserve the structure of the tooth.
                  </p>
                  <br /> <h4>Common causes of damage to the pulp:</h4>
                  <ul>
                    <li>Deep decay due to an untreated cavity</li>
                    <li>Multiple dental procedures on the same tooth</li>
                    <li>A chip or crack in the tooth</li>
                    <li>An injury to the tooth </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <img
                  className="rounded mt-5"
                  src={require("../../../assets/img/dentalrootcanal01.png")}
                  alt="Root Canal"
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
                <img
                  className="rounded mt-5"
                  src={require("../../../assets/img/InspectingTooth.png")}
                  alt="Inspecting Tooth"
                />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <div className="promo-text"></div>
                <div className="my-5">
                  <h2>Risks of a Root Canal</h2>
                  <p>
                    A root canal is performed in an effort to save your tooth.
                    Sometimes, however, the damage is too deep, or the enamel is
                    too frail to withstand the procedure. These factors can lead
                    to loss of the tooth. <br /> Another risk is developing an
                    abscess at the root of the tooth if the antibiotics are not
                    effective.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
