import React from "react";
import Appointment from "../Appointment";
import Banner from "../Banner";
import { connect } from "react-redux";
import { BUSINESS_DATA_01, BUSINESS_DATA_02 } from "../Data/BusinessData";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const Contact = ({ language }) => {
  const businessData1 = BUSINESS_DATA_01;
  const businessData2 = BUSINESS_DATA_02;
  return (
    <>
      <Banner
        pageTitle={language === "esp" ? "Contáctenos" : "Contact Us"}
        language={language}
      />

      <section className="contact-us-wrapper section-padding">
        <div className="contact-information">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <h3>{businessData1.addressCity}</h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`http://maps.google.com/?q=${businessData1.addressStreet} ${businessData1.addressCity}, ${businessData1.addressState} ${businessData1.addressZipCode}`}
                >
                  <div className="single-contact-info icon1">
                    <div className="c-icon">
                      <i className="fal fa-building" />
                    </div>
                    <div className="c-info">
                      <h4>
                        {language === "esp" ? " La Dirección " : "Address"}
                      </h4>
                      <p>{businessData1.addressStreet}</p>
                      <p>{`${businessData1.addressCity}, ${businessData1.addressState} ${businessData1.addressZipCode}`}</p>
                    </div>
                  </div>
                </a>
                <a href={`mailto:${businessData1.email}`}>
                  <div className="single-contact-info icon2">
                    <div className="c-icon">
                      <i className="fal fa-envelope" />
                    </div>
                    <div className="c-info">
                      <h4>
                        {language === "esp" ? "El Correo Electrónico" : "email"}
                      </h4>
                      <p>{businessData1.email}</p>
                    </div>
                  </div>
                </a>
                <a href={`tel:+1${businessData1.phone}`}>
                  <div className="single-contact-info icon3">
                    <div className="c-icon">
                      <i className="fal fa-phone-volume" />
                    </div>
                    <div className="c-info">
                      <h4>
                        {language === "esp" ? "El Número de Teléfono" : "Phone"}
                      </h4>
                      <p>{businessData1.phone}</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col">
                <h3>{businessData2.addressCity}</h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`http://maps.google.com/?q=${businessData2.addressStreet} ${businessData2.addressCity}, ${businessData2.addressState} ${businessData2.addressZipCode}`}
                >
                  <div className="single-contact-info icon1">
                    <div className="c-icon">
                      <i className="fal fa-building" />
                    </div>
                    <div className="c-info">
                      <h4>
                        {language === "esp" ? " La Dirección " : "Address"}
                      </h4>
                      <p>{businessData2.addressStreet}</p>
                      <p>{`${businessData2.addressCity}, ${businessData2.addressState} ${businessData2.addressZipCode}`}</p>
                    </div>
                  </div>
                </a>
                <a href={`mailto:${businessData2.email}`}>
                  <div className="single-contact-info icon2">
                    <div className="c-icon">
                      <i className="fal fa-envelope" />
                    </div>
                    <div className="c-info">
                      <h4>
                        {language === "esp" ? "El Correo Electrónico" : "email"}
                      </h4>
                      <p>{businessData2.email}</p>
                    </div>
                  </div>
                </a>
                <a href={`tel:+1${businessData2.phone}`}>
                  <div className="single-contact-info icon3">
                    <div className="c-icon">
                      <i className="fal fa-phone-volume" />
                    </div>
                    <div className="c-info">
                      <h4>
                        {language === "esp" ? "El Número de Teléfono" : "Phone"}
                      </h4>
                      <p>{businessData2.phone}</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Appointment
          title={language === "esp" ? "Ponerse en Contacto" : "Get In Touch"}
          language={language}
        />
      </section>
    </>
  );
};

export default connect(mapStateToProps)(Contact);
