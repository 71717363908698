import React from "react";
import { InsurancePage } from "./Data/PageData";
import getLanguageData from "./Helpers/getLanguageData";

const InsuranceOne = ({ language }) => {
  let insuranceData = getLanguageData(
    language,
    InsurancePage.eng,
    InsurancePage.esp
  );
  return (
    <section
      id="insuranceSection"
      className="about-wrapper about-us-one  section-padding"
    >
      <div className="container">
        <div className="row py-5">
          <div className="col-md-12 col-lg-6">
            <div className="about-us-one-left">
              <div className="about-heading">
                <h1>{insuranceData.insurance.header}</h1>
              </div>
              <p>{insuranceData.insurance.text}</p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="about-us-one-right mt-5 mt-lg-0">
              <div className="about-heading">
                <h1>{insuranceData.noInsurance.header}</h1>
              </div>
              <p>{insuranceData.noInsurance.text1}</p>
              <br />
              <p>{insuranceData.noInsurance.text2}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <a className="btn btn-info btn-lg mx-auto mt-5" href="#appointment">
            {language === "esp" ? "Solicitar Cita" : "Request Appointment"}
          </a>
        </div>
      </div>
    </section>
  );
};

export default InsuranceOne;
