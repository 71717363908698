import React, { Component, useState, useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AiFillCloseCircle } from "react-icons/ai";
import { faXingSquare } from "@fortawesome/free-brands-svg-icons";
import Popup from "reactjs-popup";
import { Modal } from "react-bootstrap";
import emailjs from "emailjs-com";
import HeroOne from "./Hero/HeroOne";
import FeaturesOne from "./Features/FeaturesOne";
import AboutOne from "./About/AboutOne";
import TeamOne from "./Team/TeamOne";
import Appointment from "./Appointment";
import GalleryOne from "./Gallery/GalleryOne";
import Promo from "./Promo";
import InsuranceOne from "./InsuranceOne";
import FeaturesData from "./Data/FeaturesData";
import TeamData from "./Data/TeamData";
import ServicePreview from "./Service/ServicePreview";
import { changeLanguage } from "../redux/ActionCreators";
import { withCookies, Cookies, useCookies } from "react-cookie";
import { instanceOf } from "prop-types";
import ErrorBoundary from "./ErrorBoundary";
import * as Sentry from "@sentry/react";

const {
  REACT_APP_EMAILJS_PROMO_TEMPLATE_ID,
  REACT_APP_EMAILJS_SERVICE_ID,
  REACT_APP_EMAILJS_USER_ID,
} = process.env;

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

function HomeOne({ language }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [cookies, setCookie] = useCookies(["language"]);
  const [email, setEmail] = useState("");
  const [showLabel, setShowLabel] = useState(false);
  const [showError, setShowError] = useState(false);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const updateEmail = (event) => {
    setEmail(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateEmail(email)) {
      emailjs
        .sendForm(
          `${REACT_APP_EMAILJS_SERVICE_ID}`,
          `${REACT_APP_EMAILJS_PROMO_TEMPLATE_ID}`,
          event.target,
          `${REACT_APP_EMAILJS_USER_ID}`
        )
        .then(
          (result) => {
            setShowLabel(true);
            setEmail("");
          },
          (error) => {
            Sentry.captureException(error);
          }
        );
    } else {
      setShowError(true);
    }
  };

  return (
    <React.Fragment>
      <ErrorBoundary>
        <HeroOne language={language} />
        <FeaturesOne
          language={language}
          items={
            language === "esp" ? FeaturesData.items_esp : FeaturesData.items_eng
          }
        />
        <AboutOne language={language} />
        <ServicePreview language={language} />
        <InsuranceOne language={language} />
        <TeamOne TeamData={TeamData} language={language} />
        <Promo language={language} />
        <GalleryOne language={language} />
        <Appointment
          title={language === "esp" ? "Solicitar Cita" : "Request Appointment"}
          language={language}
        />
        <MyVerticallyCenteredModal
          show={modalOpen}
          onHide={() => setModalOpen((o) => !o)}
          close={setModalOpen}
          updateEmail={updateEmail}
          handleSubmit={handleSubmit}
          showLabel={showLabel}
          error={showError}
        />
      </ErrorBoundary>
    </React.Fragment>
  );
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal {...props} size="md" centered>
      <Modal.Body className="p-0">
        <img
          src={require("../assets/img/implant_promo_guy.JPG")}
          alt="Implant Ad"
        />
        <button id="x" onClick={() => props.close(false)}>
          <AiFillCloseCircle />
        </button>
        <div className="subscribe-email d-flex align-items-center container">
          <div className="row justify-content-center">
            <div className="col">
              <form onSubmit={props.handleSubmit}>
                <input
                  className={`${
                    props.showLabel ? "hidden " : "subscribe-email-input "
                  }${props.error ? "error-box " : ""}`}
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  onChange={(e) => props.updateEmail(e.target.value)}
                />
                <label
                  className={!props.showLabel ? "hidden" : "success-label"}
                >
                  Email sent successfully!
                </label>
                <button
                  className={
                    props.showLabel
                      ? "hidden"
                      : "btn btn-primary subscribe-email-btn btn-sm"
                  }
                >
                  Get Promo
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default connect(mapStateToProps)(HomeOne);
