import React, { Component } from 'react';
import CountUp from 'react-countup';

export default function AboutOne(props) {
  return (
    <section className='about-wrapper about-us-one section-padding pt-0 mt-4'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 col-lg-6'>
            <div className='about-us-one-left'>
              <img
                src={require('../../assets/img/about-banner1.png')}
                alt='donto'
              />
              <img
                src={require('../../assets/img/about-banner2.png')}
                alt='donto'
                className='about-animate'
              />
            </div>
          </div>
          <div className='col-md-12 col-lg-6'>
            <div className='about-us-one-right mt-5 mt-lg-0'>
              <div className='about-heading'>
                <h1>
                  {props.language === 'esp'
                    ? 'Bienvenido a una Familia'
                    : 'Welcome to a Family'}
                </h1>
              </div>
              <p>
                {props.language === 'esp'
                  ? '¡Bienvenido a Dental Access! Utilizamos equipos de última generación con las últimas tecnologías dentales. Tratamos a todos con el mayor cuidado y respeto. Cuando está con nosotros, está con familia.'
                  : "Welcome to Dental Access! We use state-of-the-art equipment with the latest dental technologies. We treat everyone with the utmost care and respect. When you're with us, you're with family."}
              </p>
              <a href='/About' className='btn-link'>
                {props.language === 'esp' ? 'Sobre Nosotros' : 'About Us'}
              </a>
              <div className='fun-fact-section '>
                <div className='single-funfact-one'>
                  <span>
                    <CountUp end={15000} duration={3.75} />
                  </span>
                  <p>
                    {props.language === 'esp'
                      ? 'Sonrisas Felices'
                      : 'Happy Smiles'}
                  </p>
                </div>
                <div className='single-funfact-one sp-fun'>
                  <span>
                    <CountUp end={10000} duration={3.75} />
                  </span>
                  <p>{props.language === 'esp' ? 'Coronas' : 'Crowns'}</p>
                </div>
                <div className='single-funfact-one'>
                  <span>
                    {' '}
                    <CountUp end={25} duration={3.75} />
                  </span>
                  <p>
                    {props.language === 'esp'
                      ? 'Años de Experiencia'
                      : 'Years Experience'}
                  </p>
                </div>
                <div className='single-funfact-one sp-fun'>
                  <span>
                    <CountUp end={5000} duration={3.75} />
                  </span>
                  <p>{props.language === 'esp' ? 'Implantes' : 'Implants'}</p>
                </div>
                <span className='line' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
