import React from "react";
import Appointment from "../Appointment";
import SectionTitleOne from "../SectionTitleOne";
import ReactFancyBox from "react-fancybox";
import { connect } from "react-redux";
import "react-fancybox/lib/fancybox.css";
import Banner from "../Banner";
import BeforeAfterData from "../Data/BeforeAfterData";



const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const PhotoGallery = ({language}) => {
  return (
    <>
      <Banner
        pageTitle={language === "esp" ? "Nuestra Galeria" : "Our Gallery"}
        language={language}
      />
      <div className="container mt-5">
        <SectionTitleOne
          BigTitle={
            language === "esp"
              ? "Imágenes Antes y Después"
              : "Before/After Images"
          }
        />
        {BeforeAfterData.map((item, index) => {
          return (
            <div key={index} className="row">
              <div className="col-6 mx-auto">
                <div className="single-gallery-item gallery-thumbnail">
                  <ReactFancyBox
                    thumbnail={require("../../assets/img/before_after/" +
                      item.before)}
                    image={require("../../assets/img/before_after/" +
                      item.before)}
                  />
                </div>
              </div>
              <div className="col mx-auto">
                <div className="single-gallery-item gallery-thumbnail">
                  <ReactFancyBox
                    thumbnail={require("../../assets/img/before_after/" +
                      item.after)}
                    image={require("../../assets/img/before_after/" +
                      item.after)}
                    defaultThumbnailHeight={500}
                  />
                </div>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
      <Appointment
        title={language === "esp" ? "Solicitar Cita" : "Request Appointment"}
        language={language}
      />
    </>
  );
};


export default connect(mapStateToProps)(PhotoGallery);
