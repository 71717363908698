import React from "react";
import Appointment from "../Appointment";
import Banner from "../Banner";
import ServiceOne from "../Service/ServiceOne";
import ServiceData from "../Data/ServiceData";
import { connect } from "react-redux";
import getLanguageData from "../Helpers/getLanguageData";

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

const ServiceList = ({ language }) => {
  const serviceData = getLanguageData(
    language,
    ServiceData.serviceItems_Eng,
    ServiceData.serviceItems_Esp
  );

  return (
    <>
      <Banner
        pageTitle={language === "esp" ? "Servicios" : "Services"}
        language={language}
      />
      <section className="promo-content-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-6 col-12">
              <div className="promo-text">
                <h2>
                  {language === "esp"
                    ? "Una Mejor Sonrisa está Aquí"
                    : "A Better Smile is Here"}
                </h2>
                <p>
                  {language === "esp"
                    ? "Dental Access ofrece una variedad de servicios dentales. Explore nuestra selección para encontrar el servicio que mejor se adapte a lo que está buscando. Mantenemos nuestra confianza en brindarle la mejor experiencia dental."
                    : "Dental Access offers a variety of dental services. Browse our selection to find the service that best fits what you are looking for. We stand by our confidence in providing you the best dental experience."}
                </p>
                <p>
                  {language === "esp"
                    ? "Contáctenos para más información y venga para una"
                    : "Contact us for more information and come in for a"}{" "}
                  <b>
                    {language === "esp"
                      ? "consulta gratuita."
                      : "free consultation."}
                  </b>
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-12 mt-4 mt-lg-0">
              <img
                src={require("../../assets/img/service-details-promo1.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <ServiceOne ServiceData={serviceData} language={language} />

      <Appointment
        title={language === "esp" ? "Solicitar Cita" : "Request Appointment"}
        language={language}
      />
    </>
  );
};

export default connect(mapStateToProps)(ServiceList);
